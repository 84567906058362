import React from "react"
import Tagline from "../components/tagline"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Promo from "../components/promo-text"
import ContactUs from "../components/contact-us"
import Share from "../components/social-share"
import Accordion from "../components/accordion"

const InfoPage = () => (
  <Layout>
    <SEO title="Social 101" />
    <Tagline content="Where our timeline comes to life."/>

    <Promo color="dark">
        <h1>Social 101</h1>
        <p>If you’ve been watching our show and have trouble making sense of some of the things we talk about, you have come to the right place. Here you can learn about different social media platforms as well as popular social slang, acronyms, and other words or phrases we use a lot, like “influencers” or “I can’t even!” Think we missed something? Want to add to our list? Let us know!</p>
        <p>Do you have story ideas, comments about a segment, or want to get in touch with us?<br />
        You'll find us here: <a href="mailto:keepitsocial@wgbh.org"  rel="noopener noreferrer" target="_blank" className="mail-link">keepitsocial@wgbh.org</a></p>
        <Share url="https://keepitsocial.org/info/"/>
    </Promo>

    <div className="social-section">
        <div className="wrapper">
            <Accordion
                state="closed"
                title="Platforms">
                <ul>
                    <li>
                        <h3>Facebook</h3>
                        <p>Facebook is the largest social media site with over 2.4 billion active users. Its main purpose is to keep you connected with your friends and family online. You can upload a photo, share exciting news on your wall, send direct messages to your friends, watch series created for Facebook, or be a part of a group. Facebook owns Instagram and the popular messaging service WhatsApp, among other digital platforms.</p>
                    </li>
                    <li>
                        <h3>Instagram</h3>
                        <p>Instagram (IG) is a mobile-first platform that allows you to share a wide range of content such as photos and videos, preferably vertically. Part of the fun when adding content to IG is playing with captions and edit filters, tweaking settings, and sending messages to your followers. You can live stream to your story or watch some of your favorite series on IGTV -Instagram's own platform for the mobile-optimized viewing of long-form videos.  </p>
                    </li>
                    <li>
                        <h3>Pinterest</h3>
                        <p>Feeling creative? Jump on Pinterest and start pinning your interests. Find new ideas on the latest fashions or share a baking recipe with followers! This app will save you a Google search and keep you scrolling for hours. Users have put literally billions of pins on Pinterest, to help spark your inspiration. When you discover Pins you love, save them to boards to keep your ideas organized and easy to find.</p>
                    </li>
                    <li>
                        <h3>Snapchat</h3>
                        <p>Snapchat is a popular messaging app used for sharing photos and short videos (also known as snaps) between friends. Messages sent in one-on-one chats are automatically deleted. Snapchat offers users an easy way to talk with friends and explore Live Stories from around the world.</p>
                    </li>
                    <li>
                        <h3>TikTok</h3>
                        <p>TikTok is the destination for short-form (typically, 15 second) mobile videos. The platform rose from the ashes of Musical.ly and is one of the fastest growing apps on social media today. TikTok is used to create short dance, lip-sync, comedy, and talent videos. </p>
                    </li>
                    <li>
                        <h3>Tumblr</h3>
                        <p>Tumblr is a blogging and social networking site ideal for sharing content such as text, photos, videos, music, and more. The goal is to have a way to easily share all your social activity with other people in one single location. </p>
                    </li>
                    <li>
                        <h3>Twitter</h3>
                        <p>Twitter is a social networking site where you’ll find plenty of journalists, public officials, athletes, and artists, among others. You can post and receive tweets – short posts up to 280 characters. When you follow someone, you can see their tweets on your timeline and vice versa. When you log on to Twitter, you’ll always see the latest content first and you can interact with other users and join the global conversation with your own tweets.</p>
                    </li>
                    <li>
                        <h3>YouTube</h3>
                        <p>YouTube is a video sharing platform where you can watch, like, share, comment or even upload your favorite videos. YouTube has videos for everyone! You can search for an older music video from the 1960’s or watch clips for your favorite Late Night TV show that you missed. By creating your own channel, you can upload videos for your subscribers to watch, like, comment, and share.</p>
                    </li>
                </ul>

            </Accordion>

            <Accordion
                state="closed"
                title="Social Media Acronyms">
                <ul>
                    <li>
                        <h3>AMA</h3>
                        <p>Ask Me Anything</p>
                    </li>
                    <li>
                        <h3>BRB</h3>
                        <p>Be Right Back </p>
                    </li>
                    <li>
                        <h3>BTW</h3>
                        <p>By The Way</p>
                    </li>
                    <li>
                        <h3>DM</h3>
                        <p>Direct Message</p>
                    </li>
                    <li>
                        <h3>FB</h3>
                        <p>Facebook </p>
                    </li>
                    <li>
                        <h3>FBF</h3>
                        <p>Flashback Friday</p>
                    </li>
                    <li>
                        <h3>FOMO</h3>
                        <p>Fear Of Missing Out</p>
                    </li>
                    <li>
                        <h3>FYI</h3>
                        <p>For Your Information </p>
                    </li>
                    <li>
                        <h3>GTG</h3>
                        <p>Got To Go</p>
                    </li>
                    <li>
                        <h3>HBD</h3>
                        <p>Happy Birthday</p>
                    </li>
                    <li>
                        <h3>HMU</h3>
                        <p>Hit Me Up </p>
                    </li>
                    <li>
                        <h3>ICYMI</h3>
                        <p>In Case You Missed It</p>
                    </li>
                    <li>
                        <h3>IDC</h3>
                        <p>I Don’t Care</p>
                    </li>
                    <li>
                        <h3>IDK</h3>
                        <p>I Don’t Know</p>
                    </li>
                    <li>
                        <h3>IG</h3>
                        <p>Instagram </p>
                    </li>
                    <li>
                        <h3>IKR</h3>
                        <p>I Know Right </p>
                    </li>
                    <li>
                        <h3>ILY</h3>
                        <p>I Love You </p>
                    </li>
                    <li>
                        <h3>IMHO</h3>
                        <p>In My Humble Opinion</p>
                    </li>
                    <li>
                        <h3>IMO</h3>
                        <p>In My Opinion </p>
                    </li>
                    <li>
                        <h3>IRL</h3>
                        <p>In Real Life</p>
                    </li>
                    <li>
                        <h3>JK</h3>
                        <p>Just Kidding</p>
                    </li>
                    <li>
                        <h3>LMAO</h3>
                        <p>Laughing my A** Off </p>
                    </li>
                    <li>
                        <h3>LMK</h3>
                        <p>Let Me Know</p>
                    </li>
                    <li>
                        <h3>LOL</h3>
                        <p>Laugh Out Loud</p>
                    </li>
                    <li>
                        <h3>MCM</h3>
                        <p>Man Crush Monday </p>
                    </li>
                    <li>
                        <h3>MFW</h3>
                        <p>My Face When... </p>
                    </li>
                    <li>
                        <h3>MTFBWY</h3>
                        <p>May The Force Be With You</p>
                    </li>
                    <li>
                        <h3>NBD</h3>
                        <p>No Big Deal </p>
                    </li>
                    <li>
                        <h3>NM</h3>
                        <p>Nothing Much </p>
                    </li>
                    <li>
                        <h3>NVM</h3>
                        <p>Never Mind</p>
                    </li>
                    <li>
                        <h3>OMW</h3>
                        <p>On My Way</p>
                    </li>
                    <li>
                        <h3>OOTD</h3>
                        <p>Outfit of the Day </p>
                    </li>
                    <li>
                        <h3>PM</h3>
                        <p>Private Message </p>
                    </li>
                    <li>
                        <h3>PPL</h3>
                        <p>People</p>
                    </li>
                    <li>
                        <h3>ROFL</h3>
                        <p>Rolling On The Floor Laughing</p>
                    </li>
                    <li>
                        <h3>RT</h3>
                        <p>Retweet </p>
                    </li>
                    <li>
                        <h3>SMH</h3>
                        <p>Shaking My Head</p>
                    </li>
                    <li>
                        <h3>TBBH</h3>
                        <p>To Be Brutally Honest </p>
                    </li>
                    <li>
                        <h3>TBH</h3>
                        <p>To Be Honest</p>
                    </li>
                    <li>
                        <h3>TBT</h3>
                        <p>Throwback Thursday </p>
                    </li>
                    <li>
                        <h3>TFW</h3>
                        <p>That Feeling When</p>
                    </li>
                    <li>
                        <h3>TGIF</h3>
                        <p>Thank God It’s Friday</p>
                    </li>
                    <li>
                        <h3>TMI</h3>
                        <p>Too Much Information </p>
                    </li>
                    <li>
                        <h3>TOS</h3>
                        <p>Terms of Service</p>
                    </li>
                    <li>
                        <h3>WBU</h3>
                        <p>What About You?</p>
                    </li>
                    <li>
                        <h3>WBW</h3>
                        <p>Way Back Wednesday</p>
                    </li>
                    <li>
                        <h3>WFH</h3>
                        <p>Work From Home</p>
                    </li>
                    <li>
                        <h3>YOLO</h3>
                        <p>You Only Live Once</p>
                    </li>

                </ul>
            </Accordion>

            <Accordion
                state="closed"
                title="Slang">
                <ul>
                    <li>
                        <h3>¯\_(ツ)_/¯</h3>
                        <p>A popular emoji, the shrug is often used when trying to express annoyance or not knowing what to make of something. It can also be used in place of “who knows” or “why not”</p>
                    </li>
                    <li>
                        <h3>Amped</h3>
                        <p>Very excited.</p>
                    </li>
                    <li>
                        <h3>Bae</h3>
                        <p>Usually refers to a person's romantic partner, came from the word babe/baby</p>
                    </li>
                    <li>
                        <h3>Basic</h3>
                        <p>An insult that means something or someone is boring or uncool.</p>
                    </li>
                    <li>
                        <h3>Bro</h3>
                        <p>Short for “brother,” “bro” is used instead of first names among friends, typically men.</p>
                    </li>
                    <li>
                        <h3>Chill</h3>
                        <p>Relax, calm down, or be easygoing.</p>
                    </li>
                    <li>
                        <h3>Clickbait</h3>
                        <p>The practice of using a provocative title with the sole purpose of driving clicks.</p>
                    </li>
                    <li>
                        <h3>Cray</h3>
                        <p>Abbreviation for crazy</p>
                    </li>
                    <li>
                        <h3>Crush it</h3>
                        <p>Advice to do well on something, “Go crush it!”</p>
                    </li>
                    <li>
                        <h3>Curve ball</h3>
                        <p>Something unexpected happens, like trying to hit a curve ball in baseball.</p>
                    </li>
                    <li>
                        <h3>Ditch</h3>
                        <p>To leave a place or person unexpectedly, or to not show up to prior plans.</p>
                    </li>
                    <li>
                        <h3>Dude</h3>
                        <p>A casual greeting/nickname used instead of first names.</p>
                    </li>
                    <li>
                        <h3>Fam</h3>
                        <p>Short for family. Refers to a person or a group of people you consider family.</p>
                    </li>
                    <li>
                        <h3>Fire</h3>
                        <p>When something is very good. Instead of saying “This pizza is amazing” try “This pizza is fire”.</p>
                    </li>
                    <li>
                        <h3>For real</h3>
                        <p>To agree with someone, emphasize a statement, or ask if someone is serious (For real?).</p>
                    </li>
                    <li>
                        <h3>I can’t even</h3>
                        <p>Indicates someone’s inability to convey how they feel because they’re either overjoyed or frustrated with something.</p>
                    </li>
                    <li>
                        <h3>I’m down</h3>
                        <p>You agree or are interested in participating.</p>
                    </li>
                    <li>
                        <h3>Hang out</h3>
                        <p>To spend time or do something with friends.</p>
                    </li>
                    <li>
                        <h3>Legit</h3>
                        <p>Something that’s good or worthwhile. Often used in agreement. Short for legitimate (meaning authentic or real).</p>
                    </li>
                    <li>
                        <h3>Lit</h3>
                        <p>Used to describe something fun or exciting. “This party is lit”</p>
                    </li>
                    <li>
                        <h3>On fleek</h3>
                        <p>On point, looking good. </p>
                    </li>
                    <li>
                        <h3>Savage</h3>
                        <p>When someone or something is extremely harsh or successful at insulting something/someone.</p>
                    </li>
                    <li>
                        <h3>Selfie</h3>
                        <p>A picture you take of yourself, either alone or with other people. Typically taken from the front camera of a phone. </p>
                    </li>
                    <li>
                        <h3>Slay</h3>
                        <p>Like “crushing it,” you’re “slaying” something if you do it exceptionally well.</p>
                    </li>
                    <li>
                        <h3>Slide into someone’s DMs</h3>
                        <p>Sending a Direct Message to someone. This phrase of sliding refers more to a flirty way of messaging. </p>
                    </li>
                    <li>
                        <h3>Squad goals</h3>
                        <p>A term used to describe something admirable about a group of people.</p>
                    </li>
                    <li>
                        <h3>Thirsty</h3>
                        <p>When someone is too eager or seems too desperate for another.</p>
                    </li>
                    <li>
                        <h3>Throwing shade</h3>
                        <p>The act of publicly disrespecting someone personally.</p>
                    </li>
                    <li>
                        <h3>Trendjacking</h3>
                        <p>Taking over a trending topic with irrelevant content.</p>
                    </li>
                    <li>
                        <h3>Twitter Jail</h3>
                        <p>When Twitter “temporarily suspends” an account due to reaching the limit of 100 tweets in an hour or 1000 tweets in a day.</p>
                    </li>
                    <li>
                        <h3>Yaaaaaas</h3>
                        <p>A particularly enthusiastic form of “yes.” You could use as many A’s as you’d like.</p>
                    </li>

                </ul>
            </Accordion>

            <Accordion
                state="closed"
                title="Terms to Know">
                <ul>
                    <li><h3>Archiving</h3>
                    <p>To delete a photo from your feed, but not permanently. You can still have access to it without the public seeing.</p>
                    </li>

                    <li><h3>Bio</h3>
                    <p>The small portion of any profile that followers who you are. Typically, a few sentences to tell people who you are.</p>
                    </li>

                    <li><h3>Bitmoji</h3>
                    <p>Animated representation of yourself used in different situations that you can share with an app or keyboard.</p>
                    </li>

                    <li><h3>Block</h3>
                    <p>Every platform has the setting to block a specific individual from seeing your posts/profile.</p>
                    </li>

                    <li><h3>Caption</h3>
                    <p>When posting a photo or video, you can enter a caption to give your audience an idea of what is happening. Sometimes, especially on Instagram, these can be a pun or a joke. </p>
                    </li>

                    <li><h3>Check-in</h3>
                    <p>Facebook has this feature, where you can tell your friends where you are. This is often used when you are excited about what you are doing. If you ae traveling to Los Angeles, “checking in to LAX” will let your Facebook friends know you are on a trip. </p>
                    </li>

                    <li><h3>Creep</h3>
                    <p>The practice of browsing someone’s profiles, photos, and videos on social media.</p>
                    </li>

                    <li><h3>Direct message</h3>
                    <p>A private Twitter message between two users.</p>
                    </li>

                    <li><h3>Emoji</h3>
                    <p>Tiny images on your second keyboard used to convey emotions or to add a little extra to a text or post. </p>
                    </li>

                    <li><h3>Engagement</h3>
                    <p>An interaction with other people on social networks. </p>
                    </li>

                    <li><h3>Facebook/Instagram Live/ “Going Live”/ “Livestreaming”</h3>
                    <p>A feature that allows you to stream live video to friends, and followers. You can get live reactions throughout and interact with those watching in real-time.</p>
                    </li>

                    <li><h3>Feed</h3>
                    <p>The “homepage” consisting of updates, new posts, and information.</p>
                    </li>

                    <li><h3>Filter</h3>
                    <p>A photographic effect that can be applied to images before posting them, from simple black-and-white or sepia to funny faces, flower crowns and puppy ears.</p>
                    </li>

                    <li><h3>Gif (Graphics Interchange Format)</h3>
                    <p>Small animated memes used as reactions in a text, tweet, or status. </p>
                    </li>

                    <li><h3>Hashtag</h3>
                    <p>A word or phrase preceded by the “#” sign to showcase some sort of trend that you can follow.</p>
                    </li>

                    <li><h3>Influencer</h3>
                    <p>A social media user with a large audience who can gain attention about a trend, topic, company, or product by being a passionate brand advocate. </p>
                    </li>

                    <li><h3>Like</h3>
                    <p>Double tapping, or pressing thumbs up or the heart on a post because you “like it”</p>
                    </li>

                    <li><h3>Stories</h3>
                    <p>Snapchat crafted this special feature. If you want to share something to a larger audience than just one person, but don’t want to do a whole post, using the story function is the perfect medium. They are visible for 24 hours, but you can always save them in your camera roll.</p>
                    </li>
                </ul>
            </Accordion>
        </div>
    </div>
    <ContactUs />
  </Layout>
)

export default InfoPage
