import React, {useState} from "react"

const Accordion = (props) => {
    const [active, setActive] = useState(props.state);
    let aria = active === "closed" ? "false" : "true"

    function toggleAccordion() {
        setActive(active === "closed" ? "open" : "closed");
    }

    return (
        <div className="accordion">
            <div className={`trigger ` + active} onClick={toggleAccordion} ariaExpanded={aria}>
                <h2>{props.title}</h2>
                <div className={`icon ` + active}>
                    <span className="open">&#43;</span>
                    <span className="closed">&minus;</span>
                </div>
            </div>
            <div className={`content ` + active} role="region">
                {props.children}
            </div>
        </div>
    )
}

export default Accordion
